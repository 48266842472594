section.vacatures-183 {
    .block {
        padding: 0 15px;

        > h3 {
            .make-sm-column(12);
        }

        a {
            .make-xs-column(12);
            .make-sm-column(3);

            @media @small, @medium, @large {

                &:nth-child(5n+1) {
                    clear: both;
                }
            }

            @media @phone, @xsmall {
                margin-bottom: 30px;
            }

        }
    }


}

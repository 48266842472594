section.banners-127 {

    .banner-container {
        //.make-row();
        //.make-sm-column(10);
        //.make-sm-column-offset(1);

        div {
            a {
                &:extend(.banner all);

                h3 {
                    min-height: 33px;
                }
                &:hover {
                    background-color: @contrast3;
                }
                border: 2px solid @color1;
            }

            &:nth-child(even){
                .banner {
                    background: lighten(@contrast2, 20%);
                    &:hover {
                        background-color: lighten(@contrast3, 20%);
                    }
                }
            }
        }


    }
    .col {
        .make-sm-column(4);
        //.make-md-column(4);
        @media @small {
            margin-bottom: @grid-gutter-width;
        }
        /*@media @small {
            &:nth-child(2n+1){
                clear: both;
            }
        }*/
        @media @small, @medium, @large {
            &:nth-child(3n+1){
                clear: both;
            }
        }
    }

}

section.banners-123 {

    .banner-container {


    }
    .col1 {
        .make-sm-column(6);
        .make-md-column(3);
        @media @small {
            margin-bottom: @grid-gutter-width;
        }
    }
    .col2 {
        .make-sm-column(6);
        .make-md-column(3);
        @media @small {
            margin-bottom: @grid-gutter-width;
        }
    }

    .col3 {
        .make-sm-column(6);
        .make-md-column(3);
        @media @small {
            clear: both;
        }
    }
    .col4 {
        .make-sm-column(6);
        .make-md-column(3);
    }

    @media @nonmobile {
        .col2, .col4 {
            .banner {
                background: @grey;
            }
        }
    }

}


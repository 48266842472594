// --------------------------------------------------
// Custom made mixins
// --------------------------------------------------

.clearfix {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

.titlefont {
    font-family: @titlefont;
}

.font-size(@font-size: 16){
    @rem: (@font-size / 10);
    font-size: @font-size * 1px;
    font-size: ~"@{rem}rem";
}

.line-height(@size:16) {
    @rem: @size / 10;
    @px: (@size);
    line-height: ~"@{px}px";
    line-height: ~"@{rem}rem";
}

.light {
    font-weight: 200;
}

.icon {
  font-family: 'icomoon', @contentfont;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-bullets {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.col-centered {
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
.oneline { // Afgekorte tekst...
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

section.contact-131 {
    //padding-top: 0;
    //padding-bottom: 0;


    .col1 {
        .make-sm-column(6);
    }

    .col2 {
        .make-sm-column(6);
    }

    /*
    .col1 {
        .make-sm-column(6);
        .make-md-column(5);
        .make-md-column-offset(1);
        .make-lg-column(3);
        .make-lg-column-offset(2);
    }

    .col2 {
        .make-sm-column(6);
        .make-md-column(5);
        .make-lg-column(5);

    }*/


    .gmap {
        @media @medium, @large {
            height: 380px;
        }

        @media @small {
            height: 280px;
        }
        @media @phone, @xsmall {
            height: 340px;
            //max-width: 95%;
        }

    }
}

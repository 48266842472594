section.content-22 {

    .col1 {
        .make-sm-column(4);
    }

    .col2 {
        .make-sm-column(8);
    }
}

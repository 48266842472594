section.producten-232 {
    .block {
        .product-list {
            //padding: 0 15px;

            .product-item {
                .make-sm-column(3);

                @media @small, @medium, @large {
                    &:nth-child(4n+1){
                        clear: both;
                    }
                }
            }
        }

    }
}

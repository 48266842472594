.banner {
    display: block;
    text-align: left;
    margin-bottom: 30px;
    background-color: @contrast2;
    padding: 25px 15px 15px 15px;
    min-height: 120px;
    .lh-border-radius(20px);
    .lh-box-shadow(3px 4px 12px 0px fade(@black,50%));
    .clearfix;
    /*figure {
        position: relative;
        background-size: cover;
        background-position: center;
        text-align: center;
        //height: 110px;

        .make-sm-column(4);
        padding-left: 0;
        padding-right:0;

        @media @large {
            height: 170px;
        }
        @media @medium {
            height: 160px;
        }
        @media @small {
            height: 120px;
        }
        @media @phone, @xsmall {
            height: 150px;
        }


    }*/

    span {
        display: block;
        //.make-sm-column(8);
        text-align: center;

        h3 {
            .kop;
            text-align: center;
            color: @white !important;
            .font-size(17) !important;
            font-weight: 700 !important;
            margin: 0 0 10px 0 !important;
            min-height: 33px;
        }

        p {
            text-align: left;
            .font-size(18) !important;
            line-height: 1.33 !important;
            color: @white !important;

            text-decoration: none !important;
            margin: 0 0 10px 0 !important;

            @media @mobile {
                .font-size(15);
            }
        }

        &:after {
            .icon;
            display: inline-block;
            width: 30px;
            height: 30px;
            background: @color1;
            background-clip: border-box !important;
            content: '\f054';
            padding: 5px 0 0 2px;
            //margin-top: 10px;
            .lh-border-radius(0);
            color: @white;
            .font-size(20) !important;
            text-align: center;
            border: 1px solid @white;
        }


    }


    &.active, &:hover {
        text-decoration: none !important;

        h3, p, span:after {
            text-decoration: none !important;
        }

        span:after {
            background: @white;
            color: @color1;
        }
    }

}

/*.banner-up,
.banner-down {
    display: block;
    border: 4px solid transparent;
    .lh-border-radius(9);
    height: 260px;

    figure {
        width: 90%;
        max-width: 170px;
        height: 170px;
        margin: 0 auto;
        background-repeat: no-repeat;
        background-size: contain;
        .lh-transition(transform 0.2s ease);
    }

    span {
        position: absolute;
        left: 50%;
        display: block;
        width: 160px;
        height: 69px;
        margin-left: -80px;


        h3 {
            color: @black;
            text-align: center;
            margin: 0;
            .font-size(20);
            font-weight: 600;
        }
    }

    &:hover {
        figure {
            .lh-transform(scale(1.15));
        }
    }

    &.active {
        text-decoration: none;
        border-color: @white;
        figure {
            .lh-transform(scale(1)) !important;
        }
    }
}

.banner-up {

    span {
        background: url('../images/balloon-down.png') no-repeat;
        padding: 35px 5px 0 5px;
        top: 145px;

    }
}

.banner-down {
    figure {
        margin-top: 70px;
    }

    span {
        background: url('../images/balloon-up.png') no-repeat;
        padding: 5px 5px 0 5px;
        top: 13px;
    }
}*/

section.projecten-259 {

    &.wide {
        .col1 {
            padding: 0;
        }
    }

    ul {
        .no-bullets;

        li {
            position: relative;
            .make-sm-column(2);

            a {
                .lh-opacity(0.5);
                img {
                    //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
                    filter: gray; /* IE6-9 */
                    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
                }

                &:hover {
                    .lh-opacity(1);
                    img {
                        filter: none;
                        //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
                        -webkit-filter: grayscale(0%);
                    }
                }
            }
        }
    }
}

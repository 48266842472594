section.banners-125 {

    .banner-container {
        //.make-row();
        //.make-sm-column(10);
        //.make-sm-column-offset(1);

        /*div {
        a {
        &:extend(.banner all);

        span {
        text-align: left;
        &:after {
        .icon;
        content: 'lees meer\0020\e900';
        font-weight: normal;
    }
    }
    }

        &:nth-child(odd){
        h3 {
        color: @blue;
    }
        p, span:after {
        color: @grey;
    }
    }

        &:nth-child(even){

        figure {
        background-position: center bottom;
    }
        h3 {
        color: @grey;
    }
        p, span:after {
        color: @blue;
    }
    }
    }*/

    }
    .col1 {
        .make-sm-column(4);
        .make-md-column(2);
        .make-md-column-offset(1);
        @media @small {
            margin-bottom: @grid-gutter-width;
        }
    }
    .col2 {
        .make-sm-column(4);
        .make-md-column(2);
        @media @small {
            margin-bottom: @grid-gutter-width;
        }
    }

    .col3 {
        .make-sm-column(4);
        .make-md-column(2);
        @media @small {
            margin-bottom: @grid-gutter-width;
        }
    }
    .col4 {
        .make-sm-column(4);

        .make-md-column(2);
        @media @small {
            .make-sm-column-offset(2);
            clear: both;
        }
    }
    .col5 {
        .make-sm-column(4);
        .make-md-column(2);
    }

}



section.quotes-221 {
    .col1, .block-title {
        .make-sm-column(12);

        .quote-item {

            &:nth-child(4n+1) {
                clear: both;
            }
        }
    }
}

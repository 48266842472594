section.banners-122 {
    .col1 {
        .make-sm-column(4);
    }
    .col2 {
        .make-sm-column(4);
        .banner {
            background: @contrast3;
        }

    }
    .col3 {
        .make-sm-column(4);
    }


}

section.news-110 {
    .block {
        padding: 0 15px;
    }

    .news-item {
        .make-xs-column(12);
        .make-sm-column(6);
        .make-md-column(3);

        margin-bottom: 20px;
        @media @small {
            &:nth-child(2n+1) {
                clear: both;
            }
        }
        @media @medium, @large {
            &:nth-child(4n+1) {
                clear: both;
            }
        }
		&.archief {
			display: none;	
			&:nth-child(-n+2) {
				display: block;
				opacity: 0.5;
				margin-top: 30px;
			}
		}
        /*.newslink {
            height: auto;
            background: none;
            position: relative;
            &:hover {
                h3, p {
                    color: @grey !important;
                }
            }
            span {
                height: auto;
                margin-bottom: 30px;
            }
            &:after {
                border-bottom: 3px solid @color1;
                content: " ";
                width: 40%;
                margin-bottom: 20px;
                margin-top: 10px;
                left: 50%;
                position: absolute;
                bottom: 0;
                margin-left: -20%;
            }
        }*/
    }
}

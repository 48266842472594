@cookie-light-1: #fff;
@cookie-light-2: #333;
@cookie-dark-1: #333;
@cookie-dark-2: #fff;
.cookie-bar {
  position: fixed;
  z-index: 15000;
  width: 100%;
  padding: 20px;
  text-align: center;
  a {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 8px;
  }
  #cookie-close {
    float: right;
  }
  &.light {
    background-color: fade(@cookie-light-1, 90%);
    color: @cookie-light-2;
    a {
      background-color: @cookie-light-2;
      border: 1px solid @cookie-light-2;
      color: @cookie-light-1;
      text-decoration: none;
      &:hover {
        color: @cookie-light-2;
        background-color: @cookie-light-1;
      }
    }
  }
  &.dark {
    background-color: fade(@cookie-dark-1, 90%);
    color: @cookie-dark-2;
    a {
      background-color: @cookie-dark-2;
      border: 1px solid @cookie-dark-2;
      color: @cookie-dark-1;
      text-decoration: none;
      &:hover {
        color: @cookie-dark-2;
        background-color: @cookie-dark-1;
      }
    }
  }
  &.top {
    top: 0;
    left: 0;
  }
  &.bottom {
    bottom: 0;
    left: 0;
  }
}
section.content-26 {
    padding-bottom: 30px;

    .col2 {
        .make-sm-column(6);
        @media @small, @medium, @large {
            padding-right: 0;
        }
        @media @phone, @xsmall {
            margin-top: 20px;
            //padding: 0;
        }
        /*.kader {
            padding: 20px 15px 10px;
            background: @white;
            color: @black;
            border: 3px solid @black;
            .lh-border-radius(8);
        }*/

        .kader {
            *:last-child {
                margin-bottom: 0;
            }
        }

    }

    .col1 {
        .make-sm-column(6);
        @media @small, @medium, @large {
            padding-left: 0;
        }
        @media @phone, @xsmall {
            padding: 0;
        }
/*

        .kader {
            padding: 20px 15px 10px;
            border: 3px solid @black;
            .lh-border-radius(8);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            min-height: 100px;
        }
*/
        .kader {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            min-height: 200px;
        }

    }
}

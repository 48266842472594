section.carrousel-100 {

    &.wide {
        .col1 {
            padding: 0;
        }
    }

    ul {
        .no-bullets;

        li {
            position: relative;

            figure {

            }

            h5 {
                position: absolute;
                bottom: 10%;
                left: 0;
                width: 100%;
                text-align: center;
            }
        }
    }
}

section.content-40 {

    .col1 {
        .make-sm-column(3);
    }
    .col2 {
        .make-sm-column(3);
    }

    .col3 {
        .make-sm-column(3);
    }
    .col4 {
        .make-sm-column(3);
    }
}

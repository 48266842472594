section.carrousel-101 {

    &.wide {
        .col1 {
            padding: 0;
        }

        .title {
            position: relative;
            .lh-transform(translateY(120px));
            margin-top: -125px;
            z-index: 20;
            .block-title {
                color: @white !important;
                text-shadow: 0 0 12px fade(@black,80%);
            }
        }

    }

    ul {
        .no-bullets;

        li {
            position: relative;

            figure {

            }

            h5 {
                position: absolute;
                bottom: 10%;
                left: 0;
                width: 100%;
                text-align: center;
            }
        }

        &.cover {
            height: 590px;


            @media @phone, @xsmall {
                height: 360px;
            }

            li {
                height: 100%;
				height: 590px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
    }

    .bx-wrapper {
        margin-bottom: 0;

        @media @phone, @xsmall {
            figure {
                margin: 0 -50px !important;
            }

        }

    }

    .bx-wrapper .bx-pager,
    .bx-wrapper .bx-controls-auto {
        bottom: 0;
    }
}

section.content-21 {

    .col1 {
        .make-sm-column(8);
    }

    .col2 {
        .make-sm-column(4);
    }
}

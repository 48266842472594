section.vacatures-180 {
    .block {
        padding: 0 15px;
    }

    a {
        .make-sm-column(6);
        margin-bottom: 30px;

        @media @small, @medium, @large {

            &:nth-child(2n+1) {
                clear: both;
            }
        }
    }
}

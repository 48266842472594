section.news-112 {
    position: relative;
    .news-item {
        .make-xs-column(12);
        .make-sm-column(6);
        .make-md-column(3);
        .newslink {
            height: auto;
            background: none;
            &:hover {
                h3, p {
                    color: @grey !important;
                }
            }
            span {
                height: auto;
            }
        }
    }

}

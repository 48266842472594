section.contact-130 {
    padding-top: 0;
    padding-bottom: 0;

    .col1 {
        .make-sm-column(12);

        .gmap {
            margin: 10px 0 0 0;

            @media @medium, @large {
                height: 700px;
            }

            @media @small {
                height: 550px;
            }
            @media @phone, @xsmall {
                height: 300px;
                max-width: 90%;
            }

        }
    }


    &.wide {
        .col1 {
            padding: 0;
        }
    }
}

// --------------------------------------------------
// Global settings
// --------------------------------------------------

// Colors
@black: #000;
@white: #FFF;

@red: #e20a17;
@red2: #b1191a;

@grey: #506053;

@banner-text: @black;

@color1: @red;
@color2: @grey;
@contrast1: @white;
@contrast2: @red;
@contrast3: @red2;
@textcolor: @grey;
@titlecolor: @red;

// mix with white: tint()
// mix with black: shade()

// Typography
@contentfont: Arial, Helvetica, sans-serif;
@titlefont: 'Open Sans', @contentfont;
@subtitlefont: @titlefont;


// Templates
@leegheight: 400px; //400px;

// Breakpoints
/* Extra small vertical devices (phones, less than 480px) */
@phone:                     ~"only screen and (max-width: @{screen-xs-min})";

/* Extra small devices (horizontal phones, less than 768px) */
@xsmall:                    ~"only screen and (min-width: @{screen-xs-min}) and (max-width: @{screen-xs-max})";

/* Small devices (tablets, 768px and up) */
@small:                     ~"only screen and (min-width: @{screen-sm-min}) and (max-width: @{screen-sm-max})";

/* Medium devices (desktops, 992px and up) */
@medium:                    ~"only screen and (min-width: @{screen-md-min}) and (max-width: @{screen-md-max})";

/* Large devices (large desktops, 1200px and up) */
@large:                     ~"only screen and (min-width: @{screen-lg-min})";

/* Higher pixel density screens */
@retina:                    ~"only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)";

@mobile: @phone, @xsmall, @small;
@nonmobile: @medium, @large;
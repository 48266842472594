section.carrousel-103 {

    .block {
        padding: 20px 0 10px 30px !important;
        background: @white;
        color: @black;
        border: 3px solid @black;
        .lh-border-radius(8);

        @media @phone, @xsmall {
            padding: 20px 0 10px 15px !important;
        }
    }

    .col1 {
        .make-sm-column(6);


    }

    .col2 {
        .make-sm-column(6);
        padding-right: 0 !important;
        text-align: right;
    }

    .bx-wrapper,
    .bx-viewport {
        width: 170px;
        margin:0 !important;
    }
    .bxslider {
        padding-left: 0;
    }


    .background {
        display: inline-block;
        text-align: left;
        background: url('../images/hand.jpg') no-repeat left top;
        width: 100%;
        max-width: 500px;
        height:589px;
        padding: 71px 0 0 65px;
    }
}

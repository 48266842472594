* {
    .lh-box-sizing(border-box);
}

html {
    font-size: 62.5%;
}

html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
}

body {
    font-family: @contentfont;
    color: @textcolor;
    .font-size(15);
    font-weight: normal;
    //letter-spacing: 0.025em;
    line-height: 1.667;

    @media @phone, @xsmall {
        //.font-size(18);
    }
}

a {
    color: @color1;
    text-decoration: none;

    &:link {
        text-decoration: none;
        outline: 0;
    }

    &:visited {
        text-decoration: none;
        outline: 0;
    }

    &:active {
        text-decoration: none;
        outline: 0;
    }

    &:hover {
        text-decoration: underline;
        outline: 0;
    }
    .filelist & {

        &[href $='.pdf'],
        &[href $='.xls'],
        &[href $='.xlsx'],
        &[href $='.doc'],
        &[href $='.docx'],
        &[href $='.zip'],
        &[href $='.ppt'],
        &[href $='.pptx'] {
            &:not(.no-icon){
                &:before {
                    .icon;
                    display: inline-block;
                    margin-right: 0.4em;
                    font-size: 120%;
                    vertical-align: text-bottom;
                    content: "\f0f6";
                }

                &:hover {
                    text-decoration: none !important;
                    &:before {
                        text-decoration: none !important;
                    }
                }
            }
        }
        &[href $='.pdf']:before { content: '\f1c1' !important;}
        &[href $='.xls']:before,
        &[href $='.xlsx']:before { content: '\f1c3' !important;}
        &[href $='.doc']:before,
        &[href $='.docx']:before { content: '\f1c2' !important;}
        &[href $='.ppt']:before,
        &[href $='.pptx']:before { content: '\f1c4' !important;}
        &[href $='.jpg']:before,
        &[href $='.png']:before { content: '\f1c5' !important;}
        &[href $='.zip']:before { content: '\f1c6' !important;}
    }
}

.header {
    background: @white;
    position: relative;
    z-index: 25;
    .clearfix();
    /*@media @medium {
        height: 100px;
    }
    @media @small {
        height: 120px;
    }*/

    @media @phone, @xsmall {
        //min-height: 450px;
    }

    /*
    .container {
        height: 100%;
        padding: 0;
        .clearfix;
    }
    */


    .logo {
        position: relative;
        z-index: 20;
        .make-xs-column(9);
        .make-sm-column(4);
        .make-md-column(3);
        padding-left: 0;
        margin-top: 5px;
        margin-bottom: 5px;
        a {
            display: block;
            height: 100%;
        }
        img {
            max-width: 100%;
        }

        @media @small {
            //margin-top: 25px;
        }

        @media @mobile {
            margin: 12px 0;
            height: auto;
            padding-right: 0;
            text-align: center;

            img {
                //max-width: 90%;
                max-height: 60px;
            }
        }
        .clearfix;
    }

    .navbar-header {
        .make-xs-column(3);
        float: right;
        margin: 0;
        padding: 0;

        .navbar-toggle {
            background-color: @red;
            margin-right: 0;

            .icon-bar {
                background-color: @white;
            }
        }
    }
}

.header-options {
    .font-size(15);

    @media @mobile {
        display: none;
    }

    .make-sm-column(8);
    .make-md-column(9);

    text-align: right;



    ul.headerlinks {
        display: inline-block;
        .no-bullets;
        margin-top: 10px;

        li {
            display: inline-block;
            margin-left: 40px;
            a {
                text-transform: uppercase;
            }
        }
    }

}



.languages {
    //.container;
    height: 55px;
    .make-sm-column(8);
    .make-md-column(9);
    text-align: right;

    @media @phone, @xsmall {
        height: 40px;
    }

    .lang-select {
        display: inline-block;
        vertical-align: top;
        margin-top: 17px;

        @media @phone, @xsmall {
            margin-top: 10px;
        }
        @media @small {
            margin-bottom: 10px;
        }

        ul {
            .no-bullets;
            height: 20px;

            li {
                display: inline-block;
                height: 20px;
            }
        }

        a {
            display: inline-block;
            width: 30px;
            height: 20px;
            margin: 0 5px;
            background-repeat: no-repeat;
            vertical-align: top;


            &.flag_nl {
                background-image: url('../images/flag_nl.png');
            }
            &.flag_en {
                background-image: url('../images/flag_uk.png');
            }
            &.flag_fr {
                background-image: url('../images/flag_fr.png');
            }
            &.flag_de {
                background-image: url('../images/flag_de.png');
            }
            &.flag_es {
                background-image: url('../images/flag_es.png');
            }
            &.flag_sa {
                background-image: url('../images/flag_sa.png');
            }
            &.flag_cn {
                background-image: url('../images/flag_cn.png');
            }

            &:last-child {
                margin-right: 0;
            }

            .lh-opacity(0.5);

            //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
            filter: gray; /* IE6-9 */
            -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */

            &.active, &:hover {
                .lh-opacity(1);
                //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
                -webkit-filter: grayscale(0%);
            }
        }
    }
}

.storing {
    display: inline-block;
    text-transform: uppercase;
    vertical-align: bottom;
    a {
        //font-weight: bold;
        .font-size(21);
    }
    @media @mobile {
        display: none;
    }
}

.search {
    .make-sm-column(8);
    .make-md-column(9);
    text-align: right;
    margin-top: 25px;

    @media @small {
        margin-top: 0;
    }

    @media @mobile {
        padding: 0;
    }
}

.searchform {
    display: inline-block;
    @media @nonmobile {
        vertical-align: bottom;
        margin-left: 20px;
    }


    @media @mobile {
        width: 100%;
    }

    input {
        float: left;
        width: 200px;
        padding: 0 10px;
        border: 1px solid #b5baba;
        border-right: none;
        background: @white;
        height: 35px;
        vertical-align: middle;
        -webkit-appearance: none;
        .lh-border-radius(0);

        @media @mobile {
            width: ~"calc(100% - 40px)";
        }
    }
    button {
        float: left;
        width: 40px;
        text-align: center !important;
        border: 1px solid #b5baba;
        border-left: none;
        background: @white;
        height: 35px;
        vertical-align: middle;
        line-height: 1;
        -webkit-appearance: none;
        padding-top: 0;

        &:after {
            .icon;
            color: fade(@grey, 40%);
            content: '\f002';
            .font-size(20);
        }
    }
}

/*

.searchresult {
    .make-md-column(10);
    padding: 0;
    margin: 0;

    .font-size(18) !important;
    font-weight: normal !important;

    h3 {
        .font-size(20) !important;
        margin: 0 !important;
    }
    p {

    }

    &:hover {
        color: @color1 !important;
        text-decoration: none !important;

        h3 {
            color: @color1 !important;
        }
    }
}
*/

.mainmenu {
    position: relative;
    z-index: 10;
    .make-sm-column(8);
    .make-md-column(9);

    .container {
        padding: 0;
    }


    @media @nonmobile {
        margin-top: 34px;
        height: 50px;
    }

    @media @phone, @xsmall {
    }



    .menu {
        position: relative;
        z-index: 2;
        padding: 0;
        .lh-box-shadow(none);
        &.collapsing {
           // .lh-transition(height 0.85s ease-in, visibility 0.85s ease-in);
        }

        @media @medium, @large {

        }

        @media @phone, @xsmall, @small {
            padding: 5px 0;
            background: @white;
            margin: 0;
            position: absolute;
            top: 70px;
            left: -15px;
            right: -15px;
            &.in {


            }
        }
        > ul {
            width: 100%;
            .no-bullets;
            .clearfix;
            text-align: right;
            @media @phone, @xsmall {
                padding-bottom: 5px;
            }

            &.mobile-links {
                @media @nonmobile {
                    display: none;
                }
                border-top: 1px solid @white;
            }

            > li {
                position: relative;
                display: inline-block;
                overflow: hidden;

                @media @phone, @xsmall {
                    padding: 0;
                    display: block;
                    //width: 100%;
                }

                a {
                    display: block;
                    color: @red;
                    .font-size(16);
                    font-weight: normal;


                    //.lh-transition(background 0.15s ease);

                    @media @mobile {
                        padding: 5px 15px;
                        height: auto;
                        text-align: left;
                        .font-size(19);
                    }

                    @media @small {
                        .font-size(16);
                        padding: 16px 9px;
                    }

                    @media @medium {
                        padding: 16px 16px ;
                    }

                    @media @large {
                        padding: 20px 22px;
                    }
                }

                > a {

                    @media @nonmobile {
                        height: 50px;
                        line-height: 1.1;
                        text-align: center;
                    }
                }

                &:first-child {

                }

                &:hover {
                    background-color: @red;
                    > a {
                        color: @white;
                        text-decoration: none;
                    }

                    ul {
                        //display: block;
                    }
                }



                @media @small, @medium, @large {
                    display: inline-block;

                    > a {
                        //letter-spacing: 0.25px;
                    }

                    &.active {
                        background-color: @red;
                        > a {
                            color: @white;
                        }
                    }

                }

                ul {
                    .no-bullets;
                    //.lh-box-shadow(2px 2px 4px 0 rgba(0,0,0,0.2));
                    text-align: left;


                    @media @small, @medium, @large {
                        display: none;
                        background-color: @red;
                        position: absolute;
                        left: 0;
                        top: 100%;
                        z-index: 15;
                        //background: @white;
                        min-width: 300px;
                        //border: 1px solid #bbb;
                        border-top: none;
                        //padding: 10px 0 0 0;

                        li {

                            a {
                                display: block;
                                text-align: left;
                                color: @grey;
                                //background: #eee;
                                //background: fade(@white, 90%);
                                .lh-transition(background 0.2s ease);
                                padding-top: 6px;
                                padding-bottom: 6px;
                                text-transform: none;

                                &:hover {
                                    color: @white;
                                    background-color: @color1;
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    @media @phone, @xsmall {
                        margin: 0 0 15px 0;

                        li {
                            a {
                                display: block;
                                .font-size(18);
                                line-height: 1.3;
                                text-transform: none;
                                padding: 5px 15px 5px 30px;
                                font-weight: 300 !important;
                            }
                        }
                    }

                }

                &:last-child {
                    ul {
                        left: auto;
                        right: 0;
                    }
                }

            }
        }
    }
}


.top-carrousel {
    //.container;
    position: relative;
    width: 100%;
    z-index: 0;

    @media @medium, @large {
        height: 630px;
        body.page-projecten & {
            height: 800px;
        }
    }
    @media @small {
        height: 400px;
    }
    @media @phone, @xsmall {
        height: 180px;
    }

    .bx-wrapper,
    .bx-viewport {
        display: block;
        height: 100%;
    }

    ul {
        .no-bullets;
        height: 100%;
        li {

            height: 100%;
            background-repeat: no-repeat;
            background-position: center top;
            background-size: cover;

            .container {
                position: relative;
                height: 100%;
                z-index: 2;
                padding: 0;
            }

            a {
                display: block;
                height: 100%;
                span {
                    @media @mobile {
                        display: none;
                    }

                    h4 {
                        padding-right: 80px;

                        &:after {
                            position: absolute;
                            right: 0;
                            top: 0;
                            .icon;
                            content: '\f054';
                        }
                    }
                }

                &:hover {
                    span {
                        background-color: @grey;
                        h4 {

                        }
                    }
                }
            }


            span {
                display: block;

                padding: 10px 30px;
                position: absolute;
                right: 0;
                bottom: 40px;
                background-color: fade(@grey, 70%);
                .lh-box-shadow(3px 4px 12px 0px fade(@black,50%));
                .lh-border-radius(0);
                @media @phone, @xsmall {
                    right: 0;
                    padding: 5px 12px;
                }
                h4 {
                    .kop;
                    position: relative;
                    color: @white;
                    line-height: 1.1;
                    //

                    @media @small, @medium, @large {
                        .font-size(24);
                    }

                    @media @phone, @xsmall {
                        .font-size(18);
                    }


                }
            }
        }
    }
    .bx-controls {
        //position: relative;
        z-index: 20;

        @media @phone, @xsmall, @small {
            display: none;
        }

        .bx-controls-direction {
            a {
                background: fade(@white, 40%);
                text-indent: 0;
                width: 60px;
                height: 60px;
                margin-top: -25px;
                padding-top: 15px;
                text-align: center;
                text-decoration: none;
                &:before {
                    .icon;
                    .font-size(30);
                    color: @grey;

                }

                &.bx-prev {
                    left: 40px;

                    &:before {
                        content: '\f053';
                    }
                }
                &.bx-next {
                    right: 40px;

                    &:before {
                        content: '\f054';
                    }
                }

                &:hover {
                    background: fade(@color1, 70%);
                    &:before {
                        color: @white;
                    }

                }
                @media @small @phone, @xsmall {
                    background: fade(@black, 30%);
                    &.bx-prev {
                        left: 0;
                    }
                    &.bx-next {
                        right: 0;
                    }

                }
            }

        }
    }
}

.payoff {
    position: relative;
    margin: 0;
    .font-size(45);
    .kop;
    text-align: center;
    color: @white;
    background: fade(@grey,80%);
    padding-top: 22px;
    height: 90px;
    margin-top: -90px;
    z-index: 3;

    @media @mobile {
        display: none;
    }
}


.header-image {
    //.container;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    z-index: 0;

    @media @medium, @large {
        height: 500px;
    }
    @media @small {
        height: 450px;
    }
    @media @phone, @xsmall {
        height: 160px;
    }
}

.bg-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 65%;
    height: 100%;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;

}

.pageheader {
    position: relative;
    z-index: 9;
    background: none;

   hgroup {
       position: relative;
       z-index: 2;

        h1 {
            .kop;
            .font-size(30);
            color: @red;
            text-align: center;
            margin: 0;
            font-weight: 900 !important;

            @media @mobile {
                .font-size(22);
            }
        }
        h4 {
            .kop;
            .font-size(25);
            color: @white;
            font-weight: 900 !important;
            margin: 0;
            text-align: center;
            @media @mobile {
                .font-size(18);
            }
        }


    }
}


.breadcrumbs {
    clear: both;
    position: relative;
    z-index: 3;
    background: @white;
    .font-size(16);
    color: #ababab;
    font-family: @contentfont;
    text-transform: lowercase;

    @media @small, @medium, @large {
    }

    @media @phone, @xsmall {
        .font-size(14);
    }


    .container {
        padding-top: 7px;
        padding-bottom: 7px;
        //padding-left: 0;
        //padding-right: 0;
        background: transparent;
    }
    ul, ol {
        .no-bullets;

        li {
            display: inline-block;

            a {
                color: #ababab;
                &:hover {
                    color: @color1;
                    text-decoration: none;
                }
            }

            &:after {
                content: '|';
                display: inline-block;
                padding: 0 2px 0 5px;
            }

            &:last-child {
                color: #ababab;
                a {
                    color: #ababab;
                }
                &:after {
                    content: '';
                }
            }
        }
    }

    .sticky-wrapper + & {
        padding-top: 15px;
    }
}

.sidemenu {
    @media @phone, @xsmall {
        display: none;
    }

    ul {
        .no-bullets;
        li {
            border-bottom: 1px solid fade(@color1, 60%);
            a {
                position: relative;
                display: block;
                .font-size(18);
                font-weight: 300 !important;
                line-height: 1.1;
                color: @black !important;
                padding: 15px 8px 8px 8px !important;

                &:hover {
                    text-decoration: none !important;
                    background-color: fade(@color1, 20%);
                }
            }

            &.active {
                border-bottom: 0;
                > a {
                    background-color: fade(@color1, 83%);
                    color: @white !important;
                }
            }

            ul {
                border-top: 1px solid fade(@grey, 50%);
                li {
                    padding-left: 20px;
                    border-bottom: 1px solid fade(@grey, 50%);

                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }

    .page-home & {
        ul {
            li {
                a {

                }
            }
        }
    }
}


.content {
    clear: both;
    position: relative;
    z-index: 2;
    min-height: 200px;


    > section {
        padding-top: 20px;
        padding-bottom: 20px;

        p:last-child {
            margin-bottom: 0;
        }

        /*&:first-child {
            @media @small, @medium, @large {
                padding-top: 50px;
            }

            @media @phone, @xsmall {
                padding-top: 30px;
            }
        }*/

        @media @phone, @xsmall {
            //margin: 0 15px;
        }

        .block {
            .clearfix;
            //min-height: 50px;
            &:extend(.zerniq_wysiwyg all);

        }
        &:not(.wide){
            > .block {
                .container;
                padding: 0;
            }
        }
        &.wide {
            > .block {
                .container-fluid;
                padding: 0;
            }
        }


        &.first-block {
            .col-left {
                .make-sm-column(4);
                .make-md-column(3);
            }
            .col-right {
                .make-sm-column(8);
                .make-md-column(9);
            }
        }

        &.bgcolor-1 {
            background: fade(@grey,10%);
        }

        &.bgcolor-2 {
            background: fade(@red, 10%);
        }

        &.bgcolor-3 {
            background: @red;
        }

        &.bgcolor-3 {
            p, a:not(.knop), .block-title {
                color: @white !important;
            }
            h4 {
                color: @white;
            }
            h5 {
                color: @grey;
            }
            h2 {
                color: @red;
            }
            ul {
                li {
                    &:before {
                        color: @white;
                    }
                }
            }
            a.knop {
                &:hover {
                    border-color: @white !important;
                }
            }
        }



        // Homepage exceptions

        /*&:first-child {
            .page-home & {
                padding-top: 0;
                background: @grey;
                .block * {
                    color: @white !important;
                }
            }
        }*/

        &:not([class^='content-1']) .block-title {
            .make-sm-column(12);
        }
    }


    //.banners-120, .banners-121, .banners-122, .banners-123 {

    //}


    .image-block {
        min-height: 100px !important;
        background-position: center;
        background-size: cover;
    }
}

.page-home {
    .banner {
        padding: 25px 30px 20px;
        span {
            h3 {
                @media @nonmobile {
                    .font-size(30) !important;
                }
                text-align: left !important;
            }
        }
    }
}

.addthis {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    text-align: center;

    .at-resp-share-element.at-mobile .at-share-btn {
        @media @mobile {
            margin-right: 2px;
        }
    }
}
.fit-vids-style {
    max-height: 0;
}

.newslink {
   figure {
        height: 240px;
        background-position: center;
        background-size: cover;
        margin-bottom: 10px;
        position: relative;
       time {
           //display: inline-block !important;
           padding: 3px 10px;
           background: fade(@red, 70%);
           color: @white;
           bottom: 0;
           right: 0;
           position: absolute;
           margin-bottom: 0 !important;
           display: none !important;
       }
       @media @mobile {
           height: 140px;
       }
    }
    padding-bottom: 40px;
    display: block;
    @media @mobile {
        margin-bottom: 20px;
    }

    span {
        //.make-sm-column(8);
        position: relative;
        display: block;
        h3 {
            height: 26px;
            color: @color1 !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .font-size(20) !important;
            font-weight: bold;
            margin: 0 0 4px 0 !important;
            text-transform: uppercase !important;
        }
        time {
            .font-size(13) !important;
            color: #999999;
            margin-bottom: 3px !important;
        }
        p {
            .font-size(15) !important;
            font-weight: normal !important;
            margin: 0 !important;
            max-height: 81px;
            overflow: hidden;
            color: @textcolor;
        }

        &:after {
            display: inline-block;
            text-align: center;
            position: absolute;
            left: 50%;
            margin-left: -15px;
            margin-top: 10px;


            .lh-border-radius(0);
            .font-size(20) !important;
            font-weight: normal !important;

            .icon;
            display: inline-block;
            width: 30px;
            height: 30px;
            color: @white;
            background: @color1;
            content: '\f054';
            padding: 6px 0 0 2px;
        }
    }

    &:hover {
        text-decoration: none !important;
        figure {
            &:before {
                content: "";
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: fade(@red, 40%);
            }
            time {
                background-color: @grey;
            }
        }
        h3 {
            color: @color1 !important;
        }
        span:after {
            background: @color2;
        }
    }
}
/*.banner,
.newslink {
    @media @large {
        height: 520px;
    }
    @media @medium {
        height: 200px;

    }

    @media @small {
        height: 260px;
    }

    @media @phone, @xsmall {
        height: 220px;
        margin: 0 0 @grid-gutter-width 0;
    }
}*/

.project-page {
    .project-image {
        .make-sm-column(8);
        padding-bottom: 20px;
    }
    .project-content {
        .make-sm-column(10);
        .make-md-column(8);
        .make-lg-column(6);
        .col-centered;
        padding-bottom: 20px;

        .downloads {
            a {
                display: block;
                .font-size(18);
                color: @textcolor;

                &:before {
                    color: @color1;
                }
            }
        }
    }

}

.project-page-subinfo {

    .colorblock {
        padding: 30px;
        .lh-border-radius(0);
        .lh-box-shadow(3px 4px 12px 0px rgba(0, 0, 0, 0.5));
        margin: 10px 0 30px 0;
    }

    .col1 {
        .make-sm-column(6);
        .make-sm-column-offset(3);

        .colorblock {
            background: @color1;
            color: @white;
        }
    }

    .col2 {
        .make-sm-column(6);
        .make-md-column(4);
        .colorblock {
            background: @grey;
            color: @white;
        }
    }

    .terugknop {
        text-align: center;
    }
    .knop {
        clear: both;
    }
}

.project-related {
    background-color: #e8e8e8;
    .project {
        .make-sm-column(3);
    }
}

.project-nav {
    margin: 20px 0 0 0;

    padding-top: 8px;
    .clearfix;
    a {
        display: block;
        padding: 15px;
        border: 1px solid fade(@grey, 70%);
        .lh-border-radius(0);
        text-transform: uppercase;
        color: fade(@grey, 70%);

        &:before, &:after {
            display: inline-block;
            margin: 0 3px 3px 3px;
            .font-size(22);
            vertical-align: middle;
        }
        &:hover {
            text-decoration: none;
            background: fade(@grey, 10%)
        }

        @media @phone, @xsmall {
            margin-bottom: 8px;
        }
    }
    .prev {
        .make-sm-column(5);
        a:before {
            .icon;
            content: '\e5c4';
        }
    }
    .next {
        .make-sm-column(5);
        a {
            text-align: right;
            &:after {
                .icon;
                content: '\e5c8';
            }
        }
    }
    .overview {
        .make-sm-column(2);
        a {
            text-align: center;
            &:after {
                .icon;
                .font-size(30);
                content: '\e903';
            }
        }
    }
}
/*
.team-member {
    .make-sm-column(6);
    margin-bottom: 30px;
    //.make-row();
    padding: 0;

    &:nth-child(odd) {
        clear: both;
    }

    figure {
        position: relative;
        .make-sm-column(4);
        text-align: left;

        @media @phone, @xsmall {
            margin-bottom: 15px;
            img {
                width: 100%;
                max-width: 260px !important;
            }
        }

        .linkedin {
            display: block;
            position: absolute;
            bottom: 10px;
            left: 25px;
            width: 32px;
            height: 32px;
            background: @color1;
            text-align: center;
            padding-top: 3px;

            &:before {
                .icon;
                color: @white;
                content: '\f0e1';
                .font-size(24);
            }
            &:hover {
                text-decoration: none !important;
                background: @darkred;
            }
        }
    }
    .info {
        .make-sm-column(8);
        font-weight: normal;
        line-height: 1.3;
        .font-size(16);

        h3 {
            .font-size(20) !important;
            font-weight: normal !important;
            color: @color1 !important;
            margin: 0 !important;
        }

    }
}
*/
/*
.quote-item {
    .font-size(16);
    .make-sm-column(10);
    .make-sm-column-offset(1);
    text-align: center;
    padding-bottom: 15px;
    padding-top: 10px;

    q {
        display: block;
        margin-bottom: 15px;
        .font-size(30);
        line-height: 1.2;
        font-family: @scriptfont;
        font-style: italic;

        &:before,
        &:after {
            content: '';
        }
    }

    .name {
        //font-weight: bold;
    }
}*/

.news-single-item {
    &:extend(.content-10 all);
    .block {
        padding: 0 !important;
    }
    .fluid-width-video-wrapper {
        margin-bottom: 10px;
    }

    nav {
        .make-sm-column(3);
		figure {
			margin-bottom: 30px;	
		}

        @media @phone, @xsmall {
            display: none;
        }

        ul {
            .no-bullets;
            margin: 0 !important;
            li {
                //margin-bottom: 10px;
                a {
                    .font-size(16);
                    text-align: left !important;
                    line-height: 1.1 !important;
                    color: @textcolor !important;
                    background-color: transparent !important;
                    &:hover {
                        color: @white !important;
                        background-color: @color1 !important;
                        text-decoration: none !important;
                    }
                    small {
                        .font-size(12);
                    }
                }
                &:before {

                }
            }
        }
    }
    article {
        .make-sm-column(9);
        @media @phone, @xsmall {
            padding: 0 15px;
        }
    }
}

/*.contactfooter {
    border-top: 130px solid @white;
    background: @grey;
    color: @color1;
    text-align: center;




    p {
        .font-size(22);
        margin: 0;

        strong {
            font-weight: 400;
            .font-size(20);
            color: @color1;
            a {
                color: @color1;
            }
        }
    }

    .container {
        position: relative;

        @media @small,@medium  {
            padding: 20px 20px 20px 230px;
        }

        @media @large {
            padding: 20px 240px;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 20px;
            background: url('../images/bas-peijnenburg.png') no-repeat;
            width: 220px;
            height:221px;
        }
    }

    @media @phone, @xsmall {
        border-top: none;

        .container {
            padding: 15px 15px 150px 15px;

            &:after {
                background-size: contain;
                background-position: left bottom;
                height: 140px;
                left: 50%;
                margin-left: -70px;
            }
        }
    }

}*/


section.faq-210 {
    padding-top: 0;
    margin-top: -15px;
    .vraag {
        a {
            color: @color1;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
            &::before {
                .icon;
                content: '\e903';
                padding-right: 7px;
            }
            &.collapsed {
                &::before {
                    .icon;
                    content: '\e901';
                }

            }

        }
    }
}

.deurmat {
    position: relative;
    z-index: 2;
    background: @color2;
    color: @white;
    .font-size(15);
    line-height: 1.87;

    .container {
        padding-top: 30px;
        padding-bottom: 15px;
    }

    h4 {
        color: @color1;
        .font-size(20);
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1;
        margin: 0;
    }

    a {
        color: @white;
        &:hover {
            text-decoration: none;
            color: @color1;
        }
    }
	.form-group {
		margin-top: 15px;
	}
	button.knop {
        display: inline-block;
        padding: 4px 28px;
        .lh-border-radius(0);
		color: @white;
		background-color: @color1;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        font-weight: bold;
        .font-size(15);
        border: 1px solid @color1;

        &:hover {
			background: @white;
			color: @textcolor;
        }

	}

    .column {
        .make-sm-column(6);
        .make-md-column(3);

       @media @small {
            &:nth-child(3){
                clear: both;
            }
        }

        @media @phone, @xsmall,@small {
            margin-bottom: 15px;
        }
    }

    p {
        margin: 0 0 20px 0;
    }

    p + ul {
        margin-top: -20px;
    }

    ul {
        .no-bullets;
        //margin: 0 0 20px 0;
        list-style-type: none;

        li {
            position: relative;
            padding-left: 8px;
            /*&:before {
                position: absolute;
                left: 0;
                top: 0;
                content: "-\0020";
            }*/
        }
    }

}
.footer {
    position: relative;
    z-index: 2;
    padding: 8px 0;
    background: @grey;

    .block {
       .clearfix;
        &:extend(.zerniq_wysiwyg all);
       .container;
        padding: 0;
    }

    .font-size(14);

    .copyright {
        .make-sm-column(6);
        color: fade(@white,50%);
    }
    .links {
        .make-sm-column(6);
        color: fade(@white,50%);
        text-align: right;

        a {
            color: fade(@white,50%);

            &:hover {
                color: @white;
            }
        }

        a + a {
            margin-left: 20px;
        }
        ul {
            .no-bullets;
            li {
                display: inline-block;
                margin-left: 12px;
            }
        }
    }

}

.social {
    a {
        display: inline-block;
        margin-right: 15px;
        &:before {
            .icon;
            display: inline-block;
            width: 30px;
            height: 30px;
            .lh-border-radius(0);
            background: @white;
            text-align: center;
            padding-top: 3px;
            color: @grey;
            .font-size(24);
        }
        &.linkedin:before {
            content: '\f0e1';
        }
        &.twitter:before {
            content: '\f099';
        }
        &.facebook:before {
            content: '\f09a';
        }
    }
}
.footermap {
    position: relative;
    height: 200px;
    &:before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: fade(@grey, 80%);
        z-index: 4;
    }
}

.intranet-login #login-form {
    .make-sm-column(6);
    .col-centered;
}

.downloads {
    ul {
        &:extend(.filelist all);

        a {
            display: block;
            .font-size(18);
            color: @textcolor;

            &:before {
                color: @color1;
            }
        }
    }
}

section.content-24 {

    .col1 {
        .make-sm-column(3);
    }

    .col2 {
        .make-sm-column(9);
    }
}

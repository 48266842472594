.bx-wrapper {
    position: relative;
    margin: 0 auto 60px;
    padding: 0;
    *zoom: 1;

    img {
        max-width: 100%;
        display: block;
    }


    .bx-viewport {
        .lh-transform(translatez(0));
    }

    .bx-pager,
    .bx-controls-auto {
        position: absolute;
        bottom: -30px;
        width: 100%;
    }


    /* LOADING */

    .bx-loading {
        // get image from http://www.ajaxload.info/
        min-height: 50px;
        background: url(../images/bx_loader.gif) center center no-repeat;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 200;
    }

    /* PAGER */
    .bx-pager {
        text-align: center;
        font-size: .85em;
        font-family: Arial;
        font-weight: bold;
        color: #666;
        padding-top: 20px;

        .bx-pager-item {
            display: inline-block;
            *zoom: 1;
            *display: inline;

            a {
                background: #666;
                text-indent: -9999px;
                display: block;
                width: 10px;
                height: 10px;
                margin: 0 5px;
                outline: 0;
                .lh-border-radius(5px);

                &:hover,
                &.active {
                    background: #000;
                }
            }
        }


    }


    /* AUTO CONTROLS (START / STOP) */
    .bx-controls-auto {
        text-align: center;

        .bx-controls-auto-item {
            display: inline-block;
            *zoom: 1;
            *display: inline;
        }

        .bx-start {
            display: block;
            text-indent: -9999px;
            width: 10px;
            height: 11px;
            outline: 0;
            background: url(../images/bx_controls.png) -86px -11px no-repeat;
            margin: 0 3px;

            &:hover,
            &.active {
                background-position: -86px 0;
            }
        }

        .bx-stop {
            display: block;
            text-indent: -9999px;
            width: 9px;
            height: 11px;
            outline: 0;
            background: url(../images/bx_controls.png) -86px -44px no-repeat;
            margin: 0 3px;

            &:hover,
            &.active {
                background-position: -86px -33px;
            }
        }
    }



    /* DIRECTION CONTROLS (NEXT / PREV) */

    .bx-controls-direction {
        a {
            position: absolute;
            top: 50%;
            margin-top: -16px;
            outline: 0;
            width: 32px;
            height: 32px;
            text-indent: -9999px;
            z-index: 20;

            &.bx-prev {
                left: 10px;
                background: url(../images/bx_controls.png) no-repeat 0 -32px;
            }

            &.bx-next {
                right: 10px;
                background: url(../images/bx_controls.png) no-repeat -43px -32px;
            }

            &:hover {

            }
            &.disabled {
                display: none;
            }
        }
    }

    /* IMAGE CAPTIONS */
    .bx-caption {
        position: absolute;
        bottom: 0;
        left: 0;
        background: #666\9;
        background: rgba(80, 80, 80, 0.75);
        width: 100%;

        span {
            color: #fff;
            font-family: Arial;
            display: block;
            font-size: .85em;
            padding: 10px;
        }
    }

    /* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

    .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
        text-align: left;
        width: 80%;
    }

    .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
        right: 0;
        width: 35px;
    }


}




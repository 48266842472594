section.content-14 {
    .col1, .block-title {
        .make-sm-column(10);
        .col-centered;

        //padding: 0;
        @media @nonmobile {

        }
    }
}

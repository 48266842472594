section.banners-128 {
    //margin-top: 30px;
    //margin-bottom: 30px;
    padding-bottom: 0;
    position: relative;
    background: @color1;
    .col1 {

    }
}

section.news-113 {
    position: relative;

    .block {

        .news-item {
            padding: 25px 0 10px 0;

            &:nth-child(even){
                background: fade(#bbbbba, 20%);
            }
        }
    }


    .news-single-item {
        .container;

        header {
            margin-bottom: 10px;

            time {
                .kop;
                line-height: 1;
                .font-size(14) !important;
                background: @color1;

            }
            h3, h4 {
                .kop;
                @media @small, @medium, @large {
                    .font-size(30) !important;
                }
            }

            &.image {
                padding: 10px 20px;
                margin-bottom: 30px;
                min-height: 300px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;

                h3 {
                    margin-top: 110px !important;
                }
            }
        }

        .col1, .col2 {
            .make-sm-column(6);
        }
    }


}

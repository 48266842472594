section.content-31 {

    .col1 {
        .make-sm-column(6);
    }

    .col2 {
        .make-sm-column(3);
    }

    .col3 {
        .make-sm-column(3);
    }
}


.kop {
    font-family: @titlefont;
    font-weight: bold;
}

.zerniq_wysiwyg {
    font-family: @contentfont;
    line-height: 1.8;
    color: @textcolor;

    h1, h2, h4 {
        //.kop;
    }
    /*
    h1 {
        .font-size(32);
        font-weight: normal;
        margin: 0 0 0.6em 0;
        @media @phone, @xsmall {
            .font-size(26);
        }

    }*/

    h1, h2 {
        .kop;
        .font-size(34);
        color: @color1;
        font-weight: bold;
        margin: 0 0 0.6em 0;
        @media @phone, @xsmall {
            .font-size(24);
        }
        & + h4,
        & + h2 {
            margin-top: -0.5em;
        }

    }

    h4 {
        .kop;
        .font-size(21);

        color: @color2;
        margin: 0 0 10px 0;

        @media @phone, @xsmall {
            .font-size(18);
        }

    }

    .block-title {
        text-align: center;
        margin-bottom: 15px !important;
    }

    h3,h5,h6 {
        .font-size(18);
        font-weight: bold;
        margin: 0 0 1em 0;

        @media @phone, @xsmall {
            .font-size(22);
        }
    }
    h5, h6 {
        margin: 0;
    }

    h5 {
        color: @color1;
    }
    h6 {
        font-weight: normal;
    }


    a {
        color: @color1;

        &:hover {
            color: @color1;
            text-decoration: underline;
        }
    }

    img {
        max-width: 100%;
        //height: auto !important;
    }

    p {
        margin: 0 0 1.3em 0;
    }

    p + ul,
    p + ol {
        margin-top: -1.5em;
    }

    time {
        display: block;
        margin-bottom: 10px;
        font-weight: 500;
        .font-size(15);
    }
    /*
    ol {
        margin: 0;
        padding: 0;
        list-style-type: none;
        counter-reset: item;

        li {
            position: relative;
            margin: 0;
            padding-left: 50px;
            color: @color1;
            min-height: 50px;
            counter-increment: item;
            .font-size(20);
            padding-top: 2px;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: counters(item,".") " ";
                width: 40px;
                height: 40px;
                .lh-border-radius(50%);
                background: @color1;
                color: @white;
                .font-size(18);
                font-weight: 400;
                text-align: center;
                padding-top: 3px;
            }

        }
    }
    */
    ol {
        margin: 0 0 1.3em 0;
    }
    ul {
        margin: 0 0 1.3em 0;
        @media @phone {
            padding-left: 15px;

            ul {
                padding-left: 15px !important;
            }
        }

        li {
            position: relative;
        }
        &.filelist {
            list-style-type: none;

            padding-left: 5px;
            li {
                a {
                    padding: 5px 0;
                    display: inline-block;
                }
                &:before {
                    content: none;
                }
            }
        }
        &.linklist {
            margin: 1.3em auto;
            .lh-border-radius(0);
            padding: 0;
            //width: 300px;
            max-width: 100%;
            li {
                background-color: #e7e9f0;
                a {
                    display: block;
                    padding: 10px;
                    text-align: center;
                    color: @grey;
                }
                &:hover {
                    background-color: fade(@color1, 50%);
                    a {
                        color: @white;
                        text-decoration: none;
                    }
                }
                &:before {
                    content: none;

                }
            }
        }
    }



    strong {
        font-weight: bold;
    }

    .intro {
        .font-size(18);
        line-height: 1.67;
        font-weight: normal;
		margin-bottom: 20px;
    }

    .source {
        font-style: italic;
        font-size: 80%;
    }

    /*
    blockquote {
        display: block;
        text-align: center;
        background: @color1;
        color: @white;
        font-weight: 400;
        margin: 0;
        padding: 25px 25px;
        border: none;
        .font-size(28);
        .lh-border-radius(10);
        line-height: 1.25;

        @media @phone, @xsmall {
            .font-size(22);
        }

        &:before {
            content: '\201C';
        }
        &:after {
            content: '\201D';
        }
    }
    */

    blockquote {
        position: relative;
        margin: 0 10% 20px 10%;
        padding: 0 25px;
        text-align: center;
        border: none;

        line-height: 1.4;
        border-left: 5px solid @color1;


    }


    a.knop {
        display: inline-block;
        padding: 4px 28px;
        .lh-border-radius(0);
        background: @textcolor;
        color: @white;
        text-decoration: none;
        letter-spacing: 0.025em;
        font-weight: bold;
        .font-size(15);
        border: 1px solid @textcolor;

        &:hover {
            color: @white;
            background-color: @color1;
        }

    }

    .quote {
        border-top: 2px solid @color1;
        border-bottom: 2px solid @color1;
        padding: 10px 0;
        &.quote-big {
            .font-size(20);
        }

    }

    .text-handwritten {
        font-family: 'Marck Script', cursive;
        .font-size(28);
        line-height: 1;
        .lh-rotate(-2deg);
        display: inline-block;
        margin-top: 5px;
    }
}

a.project-banner {
    display: block;
    position: relative;
    figure {
        position: relative;
        height: 220px;
        background-size: cover;
        background-position: center;
        color: @white;
        text-decoration: none;
        overflow: hidden;

        @media @medium, @large {
            height: 180px;
        }

        @media @small {
            height: 140px;
        }

        @media @phone, @xsmall {
            height: 140px;
        }

        &:after {
            .lh-opacity(0);
            .lh-transition(opacity 0.2s ease);
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: @red;
        }
    }
    div {
        .lh-transition(background 0.2s ease);

        width: 100%;
        padding: 10px 0;

    }
    h3 {
        .font-size(16) !important;
        text-align: left !important;
        color: @color1 !important;
        margin: 0 !important;
        padding: 5px 0 !important;
        border: none !important;
        font-family: @contentfont !important;
		min-height: 42px;
    }

    h5 {
        .font-size(14) !important;
        color: @color2 !important;
        text-align: center;
        line-height: 1.3;
        padding: 0;
        margin: 0 !important;
        overflow: hidden;
        border: none;
        max-height: 0px;
        font-family: @contentfont !important;
        .lh-transition(max-height 0.2s ease);
    }

    &:hover {
        figure:after {
            .lh-opacity(1);
        }

        h3, h5 {
            color: @grey !important;
            text-decoration: none !important;
        }
        /*h5 {
            height: 100px;
            max-height: 100px;
        }*/
        text-decoration: none !important;
    }
}

section.projecten-256 {

    .block {
        .project-filters {
            text-align: left;
            padding-top: 10px;
            .make-sm-column(3);

            /*h6 {
                color: fade(@grey, 70%);
                .font-size(20);
                margin: 0 0 15px 0;
            }*/
            h3 {
                margin-top: 10px;
            }
            a {
                display: block;
                color: @textcolor;
                margin-right: 15px;
                .font-size(16);
                text-decoration: none;
                padding-bottom: 10px;

                &:before {
                    display: inline-block;
                    content: '';
                    width: 14px;
                    height: 14px;
                    border: 1px solid @grey;
                    margin-right: 8px;
                    //vertical-align: middle;
                    transform: skewX(12deg);
                    .lh-transition(all 0.2s ease);
                }
                &.active, &:hover {
                    color: @color1;
                    text-decoration: none;
                    &:before {
                        background-color: @grey;
                    }
                }
                &.active {
                    color: @color1;
                    &:before {
                        background-color: @red;
                    }
                }
            }

            h5 {
                color: @textcolor;
                margin: 0 0 5px 0;
                .font-size(15);
                font-weight: 600;
            }
        }

        .project-list {
            .make-sm-column(9);
            .clearfix;

            @media @mobile {
                padding: 0;
            }
        }
    }


    .project {
        .make-xs-column(12);
        .make-sm-column(4);
        .make-md-column(3);
        display: none;
        margin-bottom: 20px;

        @media @small {
            &:nth-child(3n+1){
                clear: both;
            }
        }

        @media @phone, @xsmall {
            margin-bottom: 15px;
        }
    }
/*
    .searchbox {
        position: relative;
        .make-xs-column(6);
        .make-sm-column(3);
        .make-md-column(2);
        min-width: 220px;
        .col-centered;
        margin-top: 15px;
        -webkit-appearance: none;
        border: 1px solid fade(@grey, 70%);
        .lh-border-radius(0);
        padding: 3px 40px 3px 10px;
        background: url('../images/search_bg.png') @white no-repeat right center;


        &::-webkit-input-placeholder {
            text-transform: uppercase;
        }

        &:-moz-placeholder {
            text-transform: uppercase;
        }

        &::-moz-placeholder {
            text-transform: uppercase;
        }

        &:-ms-input-placeholder {
            text-transform: uppercase;
        }
    }*/
}

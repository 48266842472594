section.content-32 {

    .col1 {
        .make-sm-column(3);
    }

    .col2 {
        .make-sm-column(6);
    }

    .col3 {
        .make-sm-column(3);
    }
}
